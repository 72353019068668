dl {
	& > div {
		margin: 0 2rem 0 0;
	}

	dt {
		font-size: 0.875rem;
		margin: 0 0 0.25rem 0;
	}
	dd {
		margin-inline-start: 0;
		font-size: 1.25rem;
	}
	
	&.horizontal {
		display: flex;
	}

	&.faq {
		dt {
			font-size: 1.5rem;
			font-weight: 500;
			margin: 0 0 0.75rem 0;
		}
		dd {
			font-size: 1.5rem;
			font-weight: 400;
			margin: 0 0 3rem 0;
		}
	}
}

table {
	font-size: 1rem;

	tr {
		th, td {
			padding: 0.25rem 1rem;
		}
	}
}

// Lists
ul {
	list-style: none;
	padding: 0;
}
ol, ul {
	li {
		margin-bottom: 1rem;
	}
}