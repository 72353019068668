@import './1_colors.scss';
@import './2_typography.scss';

body {
  margin: 0;
  padding: 0;
  font-family: $font-family-sans;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $body-bg-color;
  color: $body-font-color;

  &.trivia-theme {
    background-color: $accent-color-bg-1;
    color: $accent-color-button-text;
    background: linear-gradient(to right, $accent-color-bg-1 0%, $accent-color-bg-2 100%);
    h1, h2, h3, h4, h5, h6 {
      color: $accent-color-button-text;
      color: #eeebf7;
    }
    p {
      color: inherit;
    }
    .container.border {
      border: none;
    }
  }
}

p {
  color: $body-font-color;
}

h1, h2, h3, h4, h5, h6 {
  color: $body-font-color-lighter;
  font-weight: 500;
  /* font-family: "Romana", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #0828F5;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.d-flex {
  display: flex!important;
}

/* Gradient rects */
.rect0 {fill:url(#rg0)}
.rect1 {fill:url(#rg1)}
.rect2 {fill:url(#rg2)}
.rect3 {fill:url(#rg3)}
