@import '../1_colors.scss';

.event-description {
	max-width: 40rem;
	margin: 2rem auto 4rem auto;
	font-size: 21px;
	line-height: 1.4;
}

section.events-list {
	a.event-listing {
		font-size: 1.25rem;
    line-height: 1.3;
		display: block;
		color: $body-font-color;
		text-align: center;
		margin: 1rem auto 4rem auto;

		dl.horizontal {
			text-align: center;
			justify-content: center;
		}
	}
}