@import '../2_typography.scss';

section[name="main landing-page"] h1 {
  font-size: 2.4rem;
}
@media screen and (min-width: 601px) {
	section[name="main landing-page"] h1 {
    font-size: 3rem;
  }
}

section.landing-section h2 {
  font-size: 2rem;
}
@media screen and (min-width: 601px) {
	section.landing-section h2 {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 600px) {
	section.landing-section h2 {
		margin-bottom: 4rem;
  }
}

section.landing-section h3 {
  font-size: 1.666rem;
}
@media screen and (min-width: 601px) {
	section.landing-section h3 {
    font-size: 2rem;
  }
}

.landy-redux {
	padding: 2rem; 
	font-size: 3rem;
	text-align: left;
	overflow-x: hidden;

	p, h1, h2 {
		font-size: 3rem;
	}

	h2 {
		margin-top: 8rem;
	}

	h1, h2, h3, h4 {
		font-family: $font-family-sans;
		font-weight: 500;
	}
}

.thirst-trap {
	display: flex;
	flex-wrap: wrap;

	.logo-wrapper {
		height: 6rem;
		// margin-right: 4rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 0 2rem 0;
	}

	img.stripe-logo  { width: 8rem; }
	img.amazon-logo  { width: 10rem; }
	img.yc-logo      { width: 14rem; }
	img.square-logo  { width: 12rem; }
	img.svb-logo     { width: 14rem; }
	img.spotify-logo { width: 10rem; }
	img.artsy-logo   { width: 8rem; height: 6rem; }
	img.rothys-logo  { width: 14rem; }
	img.mux-logo     { width: 7rem; }

	&.center {
		justify-content: center;
		
		.logo-wrapper {
			margin: 0 2rem;
		}
	}
	&.left {
		width: 94vw;
		justify-content: flex-start;
		
		.logo-wrapper {
			margin-right: 4rem;
		}

	}
}

@media screen and (max-width: 600px) {
	.landy-redux {
		// padding: 2rem; 
		font-size: 2rem;

		p, h1, h2 {
			font-size: 2rem;
		}

		h2 {
			margin-top: 4rem;
		}

		.thirst-trap {
			.logo-wrapper {
				height: 3rem;
				margin-right: 2rem;
				padding: 0 0 1rem 0;
			}
			img.stripe-logo  { width: 4rem; }
			img.amazon-logo  { width: 5rem; }
			img.yc-logo      { width: 7rem; }
			img.square-logo  { width: 6rem; }
			img.svb-logo     { width: 7rem; }
			img.spotify-logo { width: 5rem; }
			img.artsy-logo   { width: 4rem; height: 3rem; }
			img.rothys-logo  { width: 7rem; }
			img.mux-logo     { width: 3.5rem; }
		}
	}
}