@import './1_colors.scss';

footer {
  ul {
    li {
      color: $bw-44-pct;
    }
  }
  a {
    color: $bw-88-pct;
    
    &:hover {
      color: $bw-97-pct;
      color: #0a21b4;
    }
  }
}
@media (min-width: 601px) {
  footer {
    ul {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr;
    }

    .right {
      text-align: right;
    }
  }
}

@media (max-width: 600px) {
  footer {
    padding: 1rem;
    text-align: left;

    ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
    form.footer-email-capture {
      input, button {
        font-size: 0.75rem;
        margin: 0;
      }
    }
  }
}