@import './1_colors.scss'; 

form {
	label {
		display: block;
		font-size: 0.8rem;
		margin: 1rem 0 0.375rem 0;
	}

	input, select, textarea, button {
		font-size: 2rem;
		padding: 0.5rem 1rem;
		margin: 0 0 1rem 0;
		box-sizing: border-box;
	}
	input[type=checkbox] {
    transform: scale(1.5);
	}
	textarea {
		padding: 0.5rem 0.75rem;
		box-sizing: border-box;
	}
	input[type="submit"], button {
  	// background-color: $accent-color-bg;
		// // border: 2px solid $accent-color-bg;
  	// color: $accent-color-button-text;
    // background: linear-gradient(to right, $tangy-yellow-button-bg-1 0%, $tangy-yellow-button-bg-2 100%);
  	text-align: center;

		&:hover {
			cursor: pointer;
  		// background-color: $accent-color;
			// border: 2px solid $accent-color;
			// transition: background-color 0.3s;
		}
	}

	input[type="submit"].button {
		display: block;
		margin: 1rem 1rem 1rem 0;
  	padding: 0.875rem 1rem;
  	// background-color: $accent-color-bg;
  	// color: #e3e3ff;
  	text-decoration: none;
		border: none;

		&:hover {
			cursor: pointer;
		}
	}

	fieldset {
		display: block;
		margin: 0;
		padding: 0;
    // margin-inline-start: 2px;
    // margin-inline-end: 2px;
    // padding-block-start: 0.35em;
    // padding-inline-start: 0.75em;
    // padding-inline-end: 0.75em;
    // padding-block-end: 0.625em;
    min-inline-size: min-content;
    border-width: 0;
    border-style: none;
    border-color: none;
    border-image: initial;

		&.full-width {
			input, select, textarea {
				width: 100%;
			}
		}
	}
}


/////////////
// SURVEYS //

.survey-question {
	p {}
	ul {
		li.option {
			border: 1px solid gray;
			border-radius: 4px;
			padding: 1rem;

			&:hover {
				cursor: pointer;
			}
			&.selected {
				background-color: #00000021;
				border: 2px solid gray;
			}
		}
	}
}
