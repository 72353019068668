// Gray scale

$bw-100-pct: #000000;
$bw-99-pct: #030303;
$bw-98-pct: #050505;
$bw-97-pct: #080808;
$bw-96-pct: #0a0a0a;
$bw-95-pct: #0d0d0d;
$bw-94-pct: #0f0f0f;
$bw-93-pct: #121212;
$bw-92-pct: #141414;
$bw-91-pct: #171717;
$bw-90-pct: #1a1a1a;
$bw-89-pct: #1c1c1c;
$bw-88-pct: #1f1f1f;
$bw-87-pct: #212121;
$bw-86-pct: #242424;
$bw-85-pct: #262626;
$bw-84-pct: #292929;
$bw-83-pct: #2c2c2c;
$bw-82-pct: #2e2e2e;
$bw-81-pct: #313131;
$bw-80-pct: #333333;
$bw-79-pct: #363636;
$bw-78-pct: #383838;
$bw-77-pct: #3b3b3b;
$bw-76-pct: #3d3d3d;
$bw-75-pct: #404040;
$bw-74-pct: #434343;
$bw-73-pct: #454545;
$bw-72-pct: #484848;
$bw-71-pct: #4a4a4a;
$bw-70-pct: #4d4d4d;
$bw-69-pct: #4f4f4f;
$bw-68-pct: #525252;
$bw-67-pct: #545454;
$bw-66-pct: #575757;
$bw-65-pct: #5a5a5a;
$bw-64-pct: #5c5c5c;
$bw-63-pct: #5f5f5f;
$bw-62-pct: #616161;
$bw-61-pct: #646464;
$bw-60-pct: #666666;
$bw-59-pct: #696969;
$bw-58-pct: #6c6c6c;
$bw-57-pct: #6e6e6e;
$bw-56-pct: #717171;
$bw-55-pct: #737373;
$bw-54-pct: #767676;
$bw-53-pct: #787878;
$bw-52-pct: #7b7b7b;
$bw-51-pct: #7d7d7d;
$bw-50-pct: #808080;
$bw-49-pct: #838383;
$bw-48-pct: #858585;
$bw-47-pct: #888888;
$bw-46-pct: #8a8a8a;
$bw-45-pct: #8d8d8d;
$bw-44-pct: #8f8f8f;
$bw-43-pct: #929292;
$bw-42-pct: #949494;
$bw-41-pct: #979797;
$bw-40-pct: #9a9a9a;
$bw-39-pct: #9c9c9c;
$bw-38-pct: #9f9f9f;
$bw-37-pct: #a1a1a1;
$bw-36-pct: #a4a4a4;
$bw-35-pct: #a6a6a6;
$bw-34-pct: #a9a9a9;
$bw-33-pct: #acacac;
$bw-32-pct: #aeaeae;
$bw-31-pct: #b1b1b1;
$bw-30-pct: #b3b3b3;
$bw-29-pct: #b6b6b6;
$bw-28-pct: #b8b8b8;
$bw-27-pct: #bbbbbb;
$bw-26-pct: #bdbdbd;
$bw-25-pct: #c0c0c0;
$bw-24-pct: #c3c3c3;
$bw-23-pct: #c5c5c5;
$bw-22-pct: #c8c8c8;
$bw-21-pct: #cacaca;
$bw-20-pct: #cdcdcd;
$bw-19-pct: #cfcfcf;
$bw-18-pct: #d2d2d2;
$bw-17-pct: #d4d4d4;
$bw-16-pct: #d7d7d7;
$bw-15-pct: #dadada;
$bw-14-pct: #dcdcdc;
$bw-13-pct: #dfdfdf;
$bw-12-pct: #e1e1e1;
$bw-11-pct: #e4e4e4;
$bw-10-pct: #e6e6e6;
$bw-9-pct:  #e9e9e9;
$bw-8-pct:  #ececec;
$bw-7-pct:  #eeeeee;
$bw-6-pct:  #f1f1f1;
$bw-5-pct:  #f3f3f3;
$bw-4-pct:  #f6f6f6;
$bw-3-pct:  #f8f8f8;
$bw-2-pct:  #fbfbfb;
$bw-1-pct:  #fdfdfd;
$bw-0-pct:  #ffffff;