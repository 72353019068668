form.email-capture {
  input, button {
		font-size: 1.25rem;
		margin: 0.5rem;
  }
}
form.footer-email-capture {
  input, button {
		font-size: 0.75rem;
		margin: 0 1rem 0rem 0;
  }
}