@import './1_colors.scss';

@font-face {
  font-family: "Romana";
  src: 
    local(""),
    url("../fonts/Romana/RomanaW03-Rom.ttf") format("truetype"),
    url("../fonts/Romana/RomanaW03-Rom.woff") format("woff");
    font-weight: normal;
}

@font-face {
  font-family: "Romana";
  src: 
    local(""),
    url("../fonts/Romana/RomanaW03-Bd.ttf") format("truetype"),
    url("../fonts/Romana/RomanaW03-Bd.woff") format("woff");
    font-weight: bold;
}

$font-family-sans: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

$font-family-serif: "Apple Garamond", "Baskerville", "Times New Roman", "Droid Serif", "Times","Source Serif Pro", serif;
$font-family-mono: "SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace;

html, body {
  font-size: 16px;
}

.container {
  font-size: 21px;
  line-height: 1.4;
}

// Default line height
p, ul, li, dt, dd, h1, h2, h3, h4, h5, h6 {
  line-height: 1.4;

  &.tight {
    line-height: 1.15;
  }
}
.tight {
  p, ul, li, dt, dd, h1, h2, h3, h4, h5, h6 {
    line-height: 1.15;
  }
}

h1, h2 {
  margin-top: 4rem;
}

a.button, input[type="submit"], button {
  display: inline-block;
  background-color: $accent-color-bg-1;
  color: $accent-color-button-text;
  background: linear-gradient(to right, $accent-color-bg-1 0%, $accent-color-bg-2 100%);
  padding: 11px 16px 13px;
  text-align: center;
  border-radius: 1px;
  border: none;
  margin: 0px auto 1rem;

  &.big {
    font-size: 1.5rem;
  }
  
  &.small {
    font-size: 0.875rem;
    padding: 8px 14px 10px;
  }
  
  &.tiny {
    font-size: 0.75rem;
    padding: 5px 11px 7px;
  }
  
  &:hover {
    cursor: pointer;
    // background: linear-gradient(to right, $accent-color-bg-2 0%, $accent-color-bg-1 100%);
    // transition: background-color 0.3s;
  }

  &.yellow {
    color: $tangy-yellow-button-text;
    background: linear-gradient(to right, $tangy-yellow-button-bg-1 0%, $tangy-yellow-button-bg-2 100%);
  }
}

h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1.25;
}
h4 {
  font-size: 1.17rem;;
}
h5 {
  font-size: 1rem;
}

.text-left   { text-align: left;   }
.text-right  { text-align: right;  }
.text-center { text-align: center; }