@import './2_typography.scss';

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

/* Utility stuff */
.position-fixed {
  position: fixed;
}
.position-absolute {
  position: absolute;
}

@media screen and (min-width: 601px) {
  .small-only, .mobile-only {
		display: none;
	}
  .hide-small {}
}
@media screen and (max-width: 600px) {
  .small-only {}
  .hide-small, .hide-mobile {
		display: none;
	}
}

.trbl-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.w-100 {
  width: 100%!important;
}
.h-100 {
  height: 100%!important;
}

/* Containers */
.container {
	//max-width: 800px;
	max-width: 720px;
	margin: 6rem auto;
	padding: 1rem 2rem 4rem 2rem;

	&.wide {
		max-width: 1200px;
	}
	&.white {
		background-color: $body-bg-white;
	}
	&.shadow {
		filter: drop-shadow(2px 2px 4px $body-bg-medium-dark);
	}
	&.border {
		border: 1px solid $body-bg-slightly-dark;
	}
	&.night {
		background-color: $midnight-container-bg;
		p, dt, ul, li {
			color: $midnight-body-font-color-main;
		}
		h1, h2, h3, h4, h5, dd {
			// color: $vibrant-periwinkle-button-text;
			color: $midnight-body-font-color-thicc;
		}
		dl.faq {
			dt {
				font-size: 1.375rem;
				font-weight: 500;
				margin: 0 0 0.75rem 0;
			}
			dd {
				font-size: 1.375rem;
				font-weight: 400;
				margin: 0 0 3rem 0;
			}
		}
	}
}

/* Grids */
.grid-2, .grid-3, .grid-4 {
	display: block;
}
@media (min-width: 601px) {
	.grid, .grid-2, .grid-3, .grid-4, .grid-4-big {
		display: grid;
	}
	.grid-2 {
		grid-template-columns: 1fr 1fr;
		column-gap: 4rem;
	}
	.grid-3 {
		grid-template-columns: 1fr 1fr 1fr;
		column-gap: 3rem;
	}
	.grid-4 {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
	.grid-4, .grid-4-big {
		column-gap: 1rem;
		row-gap: 1rem;
	}
}
@media (min-width: 601px) and (max-width: 800px) {
	.grid-4-big {
		grid-template-columns: 1fr 1fr;
	}
}
@media (min-width: 801px) and (max-width: 1200px) {
	.grid-4-big {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

@media (min-width: 1201px) {
	.grid-4-big {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}

@media (max-width: 600px) {
	.grid-2, .grid-3 {
		& > div {
			margin-bottom: 6rem;
		}
	}
	.grid-4, .grid-4-big {
		& > div {
			margin-bottom: 1rem;
		}
		& > a.panel {
			margin-bottom: 2rem;
		}
	}
}

/* Definition lists */
// dl {
// 	dt {
// 		text-transform: uppercase;
//     font-size: 75%;
//     font-family: $font-family-mono;
// 		letter-spacing: 0.1rem;

// 		margin-bottom: 0.25rem;
// 	}
// 	dd {
// 		margin-inline-start: 0; 
//     font-size: 150%;
// 	}
// }