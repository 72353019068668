@import './0p1_grayscale.scss';

/////////////////////////////////////////////
// Older black-white-blue-ish color scheme //

$vibrant-periwinkle: #3850ea;
$vibrant-periwinkle-bg: #2f33ec;
$vibrant-periwinkle-button: #0828F5;
// $secondary-color: #183090;
// $alert-color: #f04124;
// $success-color: #06B254;
// $warning-color: #f08a24;
// $info-color: #ddd;


////////////////////////////////
// Tangy + beige color scheme //

// Beige halfpipe
$pseudo-white-goldenrod: #fffefc;
$off-white-goldenrod: #f4f3ef;
$slightly-dark-goldenrod: #e6e3db; // These need better names
$eigth-dark-goldenrod: #d4d0c7;
$quarter-dark-goldenrod: #c0bbaf;

// Fluorescent lemon lime halfpipe
$tangy-yellow: #e4f222;
$tangy-yellow-button-bg-1: #E6F257;
$tangy-yellow-button-bg-2: #e3f04c;
$tangy-yellow-button-text: #191b04;

// Red-orange / scarlet
$tangy-scarlet-orange-button-bg-1: #ff4c24;

// Seafoam
$tangy-seafoam-button-bg-1: #50ddab;
$tangy-seafoam-button-bg-2: #50dd82;

$vibrant-periwinkle: #4f24ff;
$vibrant-periwinkle-button-bg-1: #4f24ff;
$vibrant-periwinkle-button-bg-2: #6e24ff;
$vibrant-periwinkle-button-text: #e4e0f3;

// $midnight-container-bg: #140836;
$midnight-container-bg: #100645;
// $midnight-body-font-color-main: #c9b8f8;
$midnight-body-font-color-main: #d2caf9;
$midnight-body-font-color-thicc: #beb5e9;

$midnight-thin-edge-1: #4017b3;
$midnight-thin-edge-2: #6a59b1;

//////////////////////
// WEBFLOW TEMPLATE //

// "black" button background
$off-black-bg: rgb(26, 27, 31);
$off-black-bg-hover: rgb(50, 52, 58);;

///////////////////////
// SITE-WIDE GLOBALS //


// $body-bg: #ffffff;
$body-bg-color: $off-white-goldenrod; // Main / most common body background color
$body-bg-white: $pseudo-white-goldenrod; // "White"
$body-bg-slightly-dark: $slightly-dark-goldenrod; // These need better names
$body-bg-little-dark: $eigth-dark-goldenrod;
$body-bg-medium-dark: $quarter-dark-goldenrod;

$body-font-color: $bw-97-pct;
$body-font-color-lighter: $bw-90-pct;

// $accent-color: $tangy-yellow;
// $accent-color-bg: $tangy-yellow-button-bg-1;
// $accent-color-button-text: $tangy-yellow-button-text;
$accent-color: $tangy-yellow;
$accent-color-bg-1: $vibrant-periwinkle-button-bg-1;
$accent-color-bg-2: $vibrant-periwinkle-button-bg-2;
$accent-color-button-text: $vibrant-periwinkle-button-text;

$primary-color: $body-font-color;

// ANIMATIONS

@keyframes spin {
	from {
			transform:rotate(0deg);
	}
	to {
			transform:rotate(360deg);
	}
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(14.0deg) }
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(14.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }
  100% { transform: rotate( 0.0deg) }
}

.say-hi {
	&:hover {
		.random-wave-emoji {
			animation-name: wave-animation;
			animation-duration: 2s;
			animation-iteration-count: infinite;
			transform-origin: 70% 70%;
			display: inline-block;
		}
	}
}