@import './1_colors.scss';

.modal-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	background-color: rgba(255, 255, 255, 0.85);
	display: flex;
	align-items: center;
}

.modal {
	box-sizing: border-box;
	width: 400px;
	min-height: 300px;
	max-height: 80vh;
	overflow: scroll;
	margin: 0 auto;
	background-color: rgba(255, 255, 255, 1);
	display: flex;
	padding: 3rem 2rem;
	position: relative;
	border: 1px solid $body-bg-slightly-dark;
	filter: drop-shadow(2px 2px 12px #999);

	.modal-inner-100 {
		width: 100%;
	}

	h1, h2, h3 {
		margin-top: 0;
	}

	.close-x {
		position: absolute;
		top: 1rem;
		right: 1rem;

		&:hover {
			cursor: pointer;
		}
	}
}

.modal-close-button {
	position: absolute;
	top: 1rem;
	right: 1rem;

	&:hover {
		cursor: pointer;
	}
}

// Night theme

.modal-overlay.night {
	background-color: rgb(37 18 130);;

	.modal {
		background-color: $midnight-container-bg;
    border: 1px solid #4017b3;
    filter: drop-shadow(2px 2px 12px #111);
		
		.close-x {
			color: $midnight-thin-edge-2;
		}
	}
}