

@media screen and (min-width: 601px) {
  .landingJuly2022 {
    .hero {
      h1 {
        font-size: 2.5rem;
      }
      p {
        font-size: 1.5rem;
      }
    }

    .feature-widget {
      .inner-grid {
        text-align: left;
        display: grid;
        column-gap: 4rem;
        margin: 4rem 0;
      }

      img {
        max-width: 100%;
      }
    }
    
  }  
}

@media screen and (max-width: 600px) {
  .landingJuly2022 {
    form.email-capture {
      input, button {
        max-width: 100%;
        width: 94vw;
        margin: 0 auto;
        display: inline-block;
      }
    }

    .hero {
      h1 {
        font-size: 2rem;
      }
      p {
        font-size: 1.25rem;
      }
    }

    .features-section {
      padding: 0 1rem;

      h1 {
        font-size: 1.5rem;
      }
    }
    
    .feature-widget {
      text-align: center;
      display: grid;
      column-gap: 1rem;
      margin: 0 0 2rem 0;
      
      img {
        width: 50vw;
        margin: 0 auto;
      }

      p {
        // text-align: left;
      }
    }
  }
}