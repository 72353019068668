// Shared
.two-panel-full {
  overflow-x: hidden;
  
  .left-col {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 4rem 2rem;

    .inner-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: end;
      text-align: left;

        p {
          margin-top: 0;
          margin-bottom: 2rem;
        }
    }
  }
  .right-col {

  }

}

@media screen and (min-width: 601px) {
  .two-panel-full {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    
    .left-col {
      .inner-wrapper {
        font-size: 2rem;
      }
    }
    .right-col {
      position: relative;
      
      img.bartender-illustration {
        height: 100vh;
        position: absolute;
        left: -6vw;
        top: 0;
        z-index: -1;
      }
    }

  }
}
@media screen and (max-width: 600px) {
  .two-panel-full {
    .left-col {
      .inner-wrapper {
        font-size: 1.5rem;
        img.bartender-illustration {
          width: 100vw;
          margin-left: -2rem;
        }
      }
    }
    .right-col {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
