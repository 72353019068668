.thirst-trap {
	display: flex;
	flex-wrap: wrap;

	.logo-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
    box-sizing: border-box;
	}

	&.center {
		justify-content: center;
		
		.logo-wrapper {
			margin: 0 2rem;
		}
	}
	&.left {
		width: 94vw;
		justify-content: flex-start;
		
		.logo-wrapper {
			margin-right: 4rem;
		}
	}
}

@media screen and (min-width: 601px) {
  .thirst-trap {
    .logo-wrapper {
      height: 6rem;
      margin: 0 0.5rem;
      padding: 0 0 2rem 0;
    }

    img.stripe-logo  { width: 8rem; }
    img.amazon-logo  { width: 10rem; }
    img.yc-logo      { width: 14rem; }
    img.square-logo  { width: 12rem; }
    img.svb-logo     { width: 14rem; }
    img.spotify-logo { width: 10rem; }
    img.artsy-logo   { width: 8rem; height: 6rem; }
    img.rothys-logo  { width: 14rem; }
    img.mux-logo     { width: 7rem; }
    
    &.center {
      .logo-wrapper {
        margin: 0 2rem;
      }
    }
    &.left {
      width: 94vw;
      
      .logo-wrapper {
        margin-right: 4rem;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .thirst-trap {
    .logo-wrapper {
      height: 4rem;
      padding: 0.5rem 0 1.5rem 0;
    }
    
    img.stripe-logo  { width: 6rem; }
    img.amazon-logo  { width: 7.5rem; }
    img.yc-logo      { width: 10.5rem; }
    img.square-logo  { width: 9rem; }
    img.svb-logo     { width: 10.5rem; }
    img.spotify-logo { width: 7.5rem; }
    img.artsy-logo   { width: 6rem; height: 4.5rem; }
    img.rothys-logo  { width: 10.5rem; }
    img.mux-logo     { width: 5.25rem; }
    
    &.center {
      .logo-wrapper {
        margin: 0 1rem;
      }
    }
    &.left {
      .logo-wrapper {
        margin-right: 2rem;
      }
    }
  }
}